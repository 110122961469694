import http from '~/utils/http'


export class CacheApi {
  // @POST('/jav/cache/cacheRecord')
  // Future<List<CacheRecord>> cacheRecord();   我的下载
  static cacheRecord(opt?: HttpOptions) {
    return http.post<Array<CacheRecord>>({
      ...opt,
      url: '/jav/cache/cacheRecord'
    })
  }

  // @POST('/jav/cache/cacheVideo')
  // Future<String> cacheVideo(@Body() CacheRecordParam param);
  static cacheVideo(params: CacheRecordParam, opt?: HttpOptions) {
    return http.post<string>({
      ...opt,
      url: '/jav/cache/cacheVideo',
      body: params
    })
  }
}
